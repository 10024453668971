<!-- 创建文章 -->
<template>
	<div class="bg-white px-3 goods_create" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<router-link :to="{name:'wenzhang_list_list'}" style="position: absolute;top: 12px;left: 200px;">
			<el-button size="mini">回到文章列表</el-button>
		</router-link>


		<el-form ref="form" label-width="80px">
			<!-- <el-form-item label="文章分类">
				<el-select v-model="form.bts" placeholder="请选择文章分类">
					<el-option v-for="(item,index) in xin" :key="index" :value="item"></el-option>
				</el-select>
			</el-form-item> -->
			
			<el-form-item label="文章标题" style="padding-top: 20px;">
				<el-input v-model="form.title" placeholder="请输入文章标题，不能超过60个字符" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="封面">
				<div>
					<span v-if="!form.cover" class="btn btn-light border mr-2" @click="chooseImage">
						<i class="el-icon-plus"></i>
					</span>
					<img v-else :src="form.cover" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="chooseImage" />
				</div>
			</el-form-item>
			
			<el-form-item label="文章分类">
				<el-select v-model="form.category_id" placeholder="请选择文章分类">
					<el-option :label="item | tree" v-for="(item,index) in cates" :key="index" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item label="文章描述">
				<el-input type="textarea" v-model="form.desc" placeholder="选填，文章卖点简述，例如：此款文章美观大方 性价比较高 不容错过" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="是否上架">
				<el-radio-group v-model="form.status">
					<el-radio :label="0" border>放入仓库</el-radio>
					<el-radio :label="1" border>立即上架</el-radio>
				</el-radio-group>
			</el-form-item>
			
		</el-form>
		<el-button type="primary" style="position: fixed;bottom: 50px;right: 50px;" @click="submit">提交</el-button>

	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				id: 0,
				cates: [],
				express: [],
				// xin:['【上新】','【必看】','【热门】'],
				form: {
					title: "", //文章名称
					category_id: "", //文章分类
					cover: "", //文章封面
					desc: "", //文章描述
					ischeck: 0, //是否审核 0待处理 1通过 2拒绝
					status: 0, //是否上架 0仓库 1上架
					bts:"",
				}
			}
		},
		// 过滤器 文章无限级分类
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		// 生命周期
		created() {
			// 获取id
			this.id = this.$route.params.id //拿到当前文章id
			if (this.id) {
				// 获取之前的文章详情
				this.layout.showLoading()
				this.axios.get('/admin/wenzhang/read/' + this.id, { //获取之前的文章详情 的api
					token: true
				}).then(res => {
					let data = res.data.data
					this.form = data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}

			// 获取文章分类api
			this.axios.get('/admin/wenzhang/create', {
				token: true
			}).then(res => {
				let data = res.data.data
				console.log(data)
				this.cates = data.cates
				this.express = data.express.list
			})
		},
		methods: {
			chooseImage() {
				this.app.chooseImage((res) => {
					this.form.cover = res[0].url
				}, 1)
			},
			//提交
			submit() {
				this.layout.showLoading()
				let url = this.id ? '/admin/wenzhang/' + this.id : '/admin/wenzhang'
				this.form.title = this.form.bts + this.form.title
				this.axios.post(url, this.form, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: this.id ? "修改成功" : "发布成功" //如果this.id存在,就是修改成功,否则就是发布成功
					})
					this.$router.push({
						name: "wenzhang_list_list"
					})
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>
	.goods_create .el-form-item {
		margin-bottom: 15px;
	}
</style>
